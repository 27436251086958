<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="wrap-content">
                <div class="title-page">Deposit</div>
                <div class="filter">
                    <div>
                        <div class="wrap-input">
                            <label class="label-input">Depositor</label>
                            <select>
                                <option selected value="all">ALL</option>
                                <option value="lp1">LP1</option>
                                <option value="lp2">LP2</option>
                                <option value="lp3">LP3</option>
                            </select>
                        </div>
                        <div class="wrap-input">
                            <label class="label-input">Total Weight</label>
                            <span>gr</span>
                        </div>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <label class="label-input">Periode</label>
                            <select>
                                <option selected value="all">Year</option>
                                <option value="lp1">Monthly</option>
                                <option value="lp2">Weekly</option>
                                <option value="lp3">Daily</option>
                            </select>
                        </div>
                        <div class="wrap-input">
                            <label class="label-input">Metal Type</label>
                            <span>Gold</span>
                        </div>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <label class="label-input">Trading Contract</label>
                            <select>
                                <option selected value="all">ALL</option>
                                <option value="lp1">KAU 0.01gr</option>
                                <option value="lp2">1gr</option>
                            </select>
                        </div>
                        <div class="wrap-input">
                            <label class="label-input"></label>
                        </div>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <label class="label-input">Allocated</label>
                            <select>
                                <option selected value="all">ALL</option>
                                <option value="lp1">Pool</option>
                                <option value="lp2">Non Pool</option>
                            </select>
                        </div>
                        <div class="wrap-input">
                            <label class="label-input">Hub</label>
                            <span>Jakarta</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <table>
                        <thead>
                            <tr id="top">
                                <th scope="col" rowspan="2" style="width: 70px">No</th>
                                <th scope="col" rowspan="2" style="width: 120px">Deposit Date</th>
                                <th scope="col" rowspan="2">Dimension (mm<sup>3</sup>)</th>
                                <th scope="col" rowspan="2">Fineness</th>
                                <th scope="col" rowspan="2">Hallmark</th>
                                <th scope="col" rowspan="2">Weight (gr)</th>
                                <th scope="col" rowspan="2">Status</th>
                                <th scope="col" colspan="2">Description</th>
                                <th scope="col" rowspan="2">Action</th>
                            </tr>
                            <tr>
                                <th scope="col">Serial Number</th>
                                <th scope="col">Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, i) in loadData" :key="i" v-show="loadData.lengthData != 0">
                                <td data-label="No">{{ i + 1 }}</td>
                                <td data-label="Depasite Date">{{ data.metal_date | formatDateTime }}</td>
                                <td data-label="Dimension">{{ data.dimension }}</td>
                                <td data-label="Fineness">{{ data.fineness }}</td>
                                <td data-label="Hallmark">{{ data.hallmark }}</td>
                                <td data-label="Weight">{{ data.weight }}</td>
                                <td data-label="Status">
                                    <span v-show="data.status == '001'" class="abx">Deliver to Vault</span>
                                    <span v-show="data.status == '002'" class="operator">Vault Process</span>
                                    <span v-show="data.status == '003'" class="clearing">Clearing Prcess</span>
                                    <span v-show="data.status == '004'" class="stored">Stored</span>
                                </td>
                                <!-- Request, clearing confirmation, abx confirmation, on deliveri, done  status-->
                                <td data-label="Serial Number">{{ data.serial_number }}</td>
                                <td data-label="Year">{{ data.year }}</td>
                                <td data-label="Action">
                                    <router-link :to="'/operator-detail-deposit/' + data._id">
                                        <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                                    </router-link>
                                </td>
                            </tr>
                            <tr v-show="loadData.lengthData == 0">
                                <td colspan="9">No Data Available</td>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="lengthData"
                        :per-page="perPage"
                        @change="handlePageChange"
                        aria-controls="new-table"
                        first-number
                        last-number
                        v-show="lengthData > 10"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import Loading from "../../components/Loader.vue";
export default {
    components: {
        // Loading,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            type: "deposit",
            trade_id: [],
            today: "",
            message: "",
            isLoading: false,
            form: {
                startDate: "",
                endDate: "",
            },
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.getData(this.perPage, this.currentPage, this.form.startDate, this.form.endDate, this.type);
    },
    methods: {
        handlePageChange(value) {
            this.getData(this.perPage, value, this.form.startDate, this.form.endDate, this.type);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterDate() {
            axios;
        },
        getData(perPage, currentPage, startDate, endDate, type) {
            this.$store.dispatch("loadDeposit", {
                perPage: perPage,
                currentPage: currentPage,
                startDate: startDate,
                endDate: endDate,
                type: type,
            });
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.nameState = valid;
            return valid;
        },
        resetModal() {
            this.name = "";
            this.nameState = null;
        },
        showModal() {
            this.$refs["my-modal"].show();
        },
        hideModal() {
            this.$refs["my-modal"].hide();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            // Push the name to submitted names
            this.submittedNames.push(this.name);
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        loadData() {
            return this.$store.state.bullionModule.dataShow;
        },
        lengthData() {
            return this.$store.state.bullionModule.lengthData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

label {
    width: 50%;
    text-align: left;
}

.select-input,
input {
    width: 100%;
}

.form-group {
    display: flex;
}

.select-input,
input {
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 0 10px;
    font-size: 13px;
}

.button-add {
    text-align: right;
    font-size: 13px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    flex-wrap: wrap;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 110px;
}
select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.clearing {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.operator,
.error {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.stored {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
.abx {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
</style>
